/*** IMPORTS FROM imports-loader ***/
var define = false;

import TimelineMax from "gsap/TimelineMax";
import SplitText from "../../lib/gsap-pro/SplitText";

export default {
    init: (app, Swiper) => {


        /* anim title */
        var timeline = new TimelineMax({paused: true});
        var $splitItem = new SplitText(".gstitle", {type: 'words, chars'});
        timeline.staggerFrom($splitItem.chars, 0.8, {y: 10, autoAlpha: 0, transformOrigin: "0% 50% -50", ease: Back.easeOut}, '0.012')
        timeline.play()

        /* anim block */
        window.sr = ScrollReveal();
        sr.reveal('.blocAnime', {
            delay: 0, distance: '20px', duration: 600, easing: 'ease-in-out', scale: 0.99, origin: 'bottom', reset: false
        });

        /* slider candidatures */
        var mySwiper = new Swiper('.swiper-candidatures', {
            spaceBetween: 20,
            slidesPerView: 3.5,
            loop: true,
            loopedSlides: 16,
            navigation: {
                nextEl: '.swiper-next',
                prevEl: '.swiper-prev',
            },
            autoplayDisableOnInteraction: true,
            breakpoints: {
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                768: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                }
            }
        });

        /* scroll top */
        $(window).scroll(function () {
            if ($(this).scrollTop() > 200) {
                $('.scrollToTop').fadeIn();
            } else {
                $('.scrollToTop').fadeOut();
            }
        });
        $('.scrollToTop').click(function () {
            $('html, body').animate({scrollTop: 0}, 800);
            return false;
        });




        $('.navigation-col nav .navbar-toggler').click(function () {
            $(".in-mob").toggleClass("in-light");
        });


        // $(":file").jfilestyle({dragdrop: false}); 



    }
}
